<template>
  <div class="hire">
    <div class="hire__title">
      <div class="hire__title-options">
        <div class="hire__title-link" @click="back">
          <img src="@/assets/icons/icon-arrow.svg" alt="" />
        </div>
        <PageTitle text="Найм работника на вакансию в проекте" />
      </div>
      <div class="hire__title-options">
        <el-button type="primary" @click="back">
          Отмена
        </el-button>
        <el-button
          type="primary"
          :disabled="!selectedWorker || isDisabled"
          @click="hireEmployee"
          v-text="'Нанять'"
        />
      </div>
    </div>
    <div class="hire__table">
      <el-table
        :data="employeesList"
        @header-click="sortData"
      >
        <el-table-column
          width="360"
          :index="0"
          :label-class-name="typeSort === 'title' ? 'sortable ' + sortOrder : ''"
        >
          <template slot="header">
			  <span class="hide-sm  hide-xs">Полное имя</span>
			  <span class="hide-lg  hide-md">Пользователь</span>
            <svg
              width="6"
              height="9"
              viewBox="0 0 6 9"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M3 5H0L3 9L6 5H3Z" fill="#A2A7BE" />
              <path d="M3 4L6 4L3 0L0 4L3 4Z" fill="#A2A7BE" />
            </svg>
          </template>
          <template slot-scope="scope">
            <div class="hire__table-item">
              <span class="hire__table-item--bold">
                {{ scope.row.name }}
              </span>
              <br />
              <span>
                {{ scope.row.phone }}
              </span>
            </div>
          </template>
        </el-table-column>
        <el-table-column width="260">
          <template slot="header" slot-scope="scope">
            <div class="table-th--title">
              Специальность
            </div>
            <el-select
              v-if="specialitiesList && specialitiesList.length"
              :key="scope.column.index"
              v-model="selectedSpecialities"
              filterable
              class="el-select_filter"
              @change="getEmployee"
              placeholder="Все специальности"
            >
              <el-option
                v-for="item in specialitiesAllList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </template>
          <template slot-scope="scope">
            <div v-if="scope.row.specialities && scope.row.specialities.length">
              <div v-for="item in scope.row.specialities" :key="item.id">
                {{ item.title }}
              </div>
            </div>
            <div v-else>
              Нет специальностей
            </div>
          </template>
        </el-table-column>
        <el-table-column :index="6">
			<template slot="header" slot-scope="scope">
				<span class="hide-sm  hide-xs">Проекты</span>
				<span class="hide-lg  hide-md">Специальность</span>
			</template>
          <template slot-scope="scope">
            <div v-if="scope.row.projects && scope.row.projects.length">
              <div v-for="item in scope.row.projects" :key="item.id">
                {{ item.title }}
              </div>
            </div>
            <div v-else>
              Нет проектов
            </div>
          </template>
        </el-table-column>
        <el-table-column
          width="100"
        >
          <template slot-scope="scope">
            <label
              class="el-checkbox"
              :class="{ 'is-checked': scope.row.id === selectedWorker }"
            >
              <span class="el-checkbox__input" :class="{'is-checked' : scope.row.id === selectedWorker}">
                <span class="el-checkbox__inner"></span>
                <input
                  type="radio"
                  aria-hidden="false"
                  class="el-checkbox__original"
                  :value="scope.row.id"
                  v-model="selectedWorker"
                >
              </span>
            </label>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="hire__nav">
      <Pagination :info="pagination" @changedPage="changePage" />
    </div>
  </div>
</template>

<script>
import PageTitle from '../components/elements/PageTitle'
import Pagination from '../components/blocks/Pagination'

export default {
  name: 'HireEmployee',
  components: {
    PageTitle,
    Pagination,
  },
  data() {
    return {
      typeSort: '',
      ascOrder: true,
      page: 1,
      pageCount: 10,
      selectedSpecialities: null,
      selectedWorker: null,
      isDisabled: false,
    }
  },
  computed: {
    employeesList() {
      return this.$store.state.Employee.employeesList
    },
    pagination() {
      return this.$store.state.Employee.pagination
    },
    specialitiesList() {
      return this.$store.state.Projects.specialitiesList
    },
    specialitiesAllList() {
      let arr = []
      if (this.specialitiesList) {
        arr = JSON.parse(JSON.stringify(this.specialitiesList))
        arr.unshift({ id: 'all', name: 'Все специальности' })
      }
      return arr
    },
    sortOrder() {
      let order = 'asc'
      if (!this.ascOrder) order = 'desc'
      return order
    },
  },
  created() {
    this.getEmployee()
    this.$store.dispatch('Projects/getSpecialities')
  },
  methods: {
    getEmployee() {
      const params = {
        page: this.page,
        pageCount: this.pageCount,
        speciality: this.selectedSpecialities === 'all' ? null : this.selectedSpecialities,
        sortField: this.typeSort ? this.typeSort : null,
        sortBy: this.typeSort ? this.sortOrder : null,
        company: this.$route.query.company ? this.$route.query.company : null,
      }
      this.$store.dispatch('Employee/getEmployees', params)
    },
    sortData(row) {
      switch (row.index) {
        case 0:
          this.typeSort === 'title' ? this.ascOrder = !this.ascOrder : this.ascOrder = true
          this.typeSort = 'title'
          break
      }
      this.getEmployee()
    },
    changePage(page, count) {
      this.page = page
      this.pageCount = count
      this.getEmployee()
    },
    hireEmployee() {
      this.isDisabled = true
      const data = {
        worker_id: this.selectedWorker,
      }
      this.$store.dispatch('Projects/hireEmployee', {
          data,
          id: this.$route.query.vacancy,
        })
        .then(() => {
          this.$notify({
            title: 'Успех',
            message: 'Сотрудник нанят',
            type: 'success',
          })
          this.isDisabled = false
          this.back()
        })
        .catch(err => {
          console.log(err)
          this.$notify({
            title: 'Ошибка',
            message: 'Ошибка, попробуйте еще раз',
            type: 'error',
          })
          this.isDisabled = false
        })
    },
    back() {
      this.$router.go(-1)
    },
  },
}
</script>

<style lang="scss">
@import 'src/assets/styles/helpers/variables';
@import 'src/assets/styles/helpers/mixin';
@import 'src/assets/styles/helpers/tools';

.hire {
  &__title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2.4rem;
    position: relative;

    &-button {
      position: absolute;
      right: 0;
      bottom: -5rem;
    }

    &-link {
      display: flex;
      align-items: center;
      justify-content: center;
      box-shadow: 4px 4px 24px rgba(70, 80, 119, 0.04);
      border-radius: 6px;
      background: #fff;
      width: 2.8rem;
      height: 2.8rem;
      margin-right: 1rem;
      padding-right: 0.2rem;
      transition: 0.3s ease-out;

      &:hover {
        box-shadow: 4px 4px 24px rgba(70, 80, 119, 0.44);
      }
    }

    &-options {
      display: flex;
      align-items: center;
    }
  }

  &__table {
    .el-table th.is-leaf {
      height: 8.7rem;
      padding: 0;
    }
  }
}

@media (max-width: $screen-sm-max){
	.hire__title{
		flex-wrap: wrap;
	}
	.hire__title-options{
		width: 100%;

		+ .hire__title-options{
			margin-top: 12px;
		}

		.el-button{
			flex-grow: 1;
		}
	}
	.hire{
		.hire__table{
			margin: 0 -16px;

			.cell {
				display: block;
			}
		}

		.el-table__header-wrapper{
			margin-bottom: 4px;
		}

		.el-table__header{
			display: block;
			width: 100% !important;

			thead{
				display: block;
			}

			tr{
				display: flex;
				flex-wrap: wrap;
				background: none;
			}

			th{
				font-size: 12px;
				line-height: 16px;

				&.is-leaf{
					height: auto;

					.cell{
						padding-top: 8px;
						padding-bottom: 8px;
					}
				}

				&:nth-child(1) {
					border-radius: 0;
					width: calc(50% - 10px);
					margin-top: 16px;
					text-align: left;

					.cell{
						padding: 8px 16px;
						font-weight: 600;
					}
				}
				&:nth-child(3) {
					margin-top: 16px;
					width: calc(50% + 10px);

					.cell{
						padding-left: 0;
					}
				}

				&:last-child,
				&:nth-child(4){
					display: none;
				}

				&:nth-child(2){
					margin: 0 16px 4px;
					order: -15;
					width: 100%;
					background: none;

					.cell{
						padding: 0;
					}

					.table-th--title{
						display: none;
					}
				}

				svg{
					display: none;
				}
			}

			.el-select{
				width: 100%;
			}
		}

		.el-table__body-wrapper{
			table,
			tbody{
				display: block;
				width: 100% !important;
			}
			tr{
				display: flex;
				flex-wrap: wrap;
				padding: 16px;
				position: relative;
				margin-bottom: 4px;
			}
			td{
				position: static;
				border-radius: 0;
				padding: 0;
				border: none;
				text-align: left;
				font-size: 12px;
				line-height: 16px;

				&:nth-child(1){
					width: calc(50% - 10px);
					order: -40;
					font-size: 14px;
					line-height: 20px;
				}

				&:nth-child(2){
					width: calc(50% - 10px);
					order: -35;
				}

				&:nth-child(4){
					width: 20px;
					order: -30;
				}

				&:nth-child(3){
					display: none;
				}

				.cell{
					padding: 0;
					line-height: 20px;
				}
			}
			.hire__table-actions__chat{
				position: absolute;
				right: 16px;
				bottom: 16px;
				margin: 0;

				img{
					margin: 0;
					position: static;
				}
			}
		}
	}
	.hire__table-actions{
		padding: 0;
	}
	.hire__table-info{
		margin: 3px 0 -3px;
	}
	.remove-message__text{
		.schedule{
			padding: 0;
			margin-top: 10px;
		}
	}
}

@media (max-width: $screen-xs-max){
	.hire__title-link{
		display: none;
	}
}
</style>
